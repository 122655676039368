<template>
    <div class="conversation text-primary bg-light">
        <div class="conversation__header bg-white">
            <img
                src="@/assets/icons/left_arrow_icon.svg"
                :style="{ cursor: 'pointer', width: '20px', height: '20px' }"
                @click="$router.go(-1)"
            />
            <h3 v-if="isNewConversation">{{ $route.params.profile.name }}</h3>
            <h3 v-else>{{ getOtherName }}</h3>
            <img src="@/assets/ellipsis.svg" />
        </div>

        <div v-if="isNewConversation" :style="{ padding: '1rem' }">
            <p>
                Welcome to the beginning of the conversation between you and
                {{ $route.params.profile.name }}
            </p>
        </div>
        <Chat
            :right-id="myProfileId"
            :messages="getMessages"
            :members="getMembers"
            :loading="$apollo.queries.myConversation.loading"
            @scrolled-top="loadMoreMessages"
        ></Chat>

        <div class="conversation__input bg-light">
            <form @submit.prevent="handleSendMessage">
                <BaseInput v-model="input" placeholder="Chat" text-align="center" round required />
            </form>
        </div>
    </div>
</template>

<script>
import Chat from '@/components/chat/Chat'
import { MY_CONVERSATIONS, MY_CONVERSATION } from '@/graphql/queries/conversation.js'
import { SEND_MESSAGE } from '@/graphql/mutations/conversation.js'
import { ME } from '@/graphql/queries/user'
import { mergeUniquesById } from '@/utils/common.js'

import conversationMixin from './ConversationMixin'
import paginationMixin from './PaginationMixin'
export default {
    name: 'NewConversation',
    components: {
        Chat
    },
    mixins: [conversationMixin, paginationMixin],
    inject: ['getMyCurrentProfile', 'isProfileLoading'],
    apollo: {
        myConversations: {
            query: MY_CONVERSATIONS
        },
        me: {
            query: ME
        },
        myConversation: {
            query: MY_CONVERSATION,
            variables() {
                return {
                    id: this.$route.params.id,
                    start: 0,
                    limit: this.limit
                }
            },
            pollInterval: 15000,
            skip() {
                return this.isNewConversation
            },
            error(err) {
                console.log({ err })
                this.$router.replace('/')
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        if (!to.params.profile && !to.params.id) {
            console.log('Params not defined in ', to)
            console.log('Redirecting to', from)
            next(from)
        } else {
            next()
        }
    },
    data() {
        return {
            myConversations: [],
            myConversation: {}
        }
    },
    computed: {
        isLoading() {
            return !this.isProfileLoading() || this.$apollo.queries.myConversation.loading
        },
        myProfileId() {
            return this.getMyCurrentProfile()?.id || ''
        },
        isNewConversation() {
            return !!this.$route.params.profile
        },
        getOther() {
            const { id } = this.getMyCurrentProfile()
            return this.getMembers.find((profile) => profile.id !== id)
        },
        getOtherName() {
            return this.getOther?.name
        },
        getMembers() {
            return this.myConversation?.members || []
        },
        getMessages() {
            const messages = this.myConversation?.messages || []

            return [...messages].reverse()
        }
    },
    created() {
        // Redirect to /conversation/:id if the there already exists a conversation
        // between me and user.
        if (this.isNewConversation) {
            const { id: otherProfileId } = this.$route.params.profile
            if (this.isExistingConversationWithUser(otherProfileId)) {
                console.log('There is already a conversation between these profiles')
                const conversationId = this.getConversationIdByUser(otherProfileId)
                this.$router.replace(`/conversation/${conversationId}`)
            }
        }
    },
    methods: {
        loadMoreMessages() {
            this.fetchMore(this.$apollo.queries.myConversation, (previous, { fetchMoreResult }) => {
                // console.log('previous result', previous)
                // console.log('next result', next)
                return {
                    myConversation: {
                        ...previous.myConversation,
                        messages: mergeUniquesById(
                            previous.myConversation.messages,
                            fetchMoreResult.myConversation.messages
                        )
                    }
                }
            })
        },
        getConversationIdByUser(userId) {
            const { id: myId } = this.getMyCurrentProfile()
            return this.myConversations.find((conversation) => {
                return (
                    conversation.members.some((member) => member.id === userId) &&
                    conversation.members.some((member) => member.id == myId)
                )
            }).id
        },
        isExistingConversationWithUser(id) {
            const { id: myId } = this.getMyCurrentProfile()

            return this.myConversations.some((conversation) => {
                return (
                    conversation.members.some((member) => member.id === id) &&
                    conversation.members.some((member) => member.id == myId)
                )
            })
        },
        async handleSendMessage() {
            if (this.isNewConversation) {
                this.handleSendInitialMessage()
            } else {
                const message = this.extractInputText()
                const { id: otherId, type: otherType } = this.getOther
                await this.$apollo.mutate({
                    mutation: SEND_MESSAGE,
                    variables: {
                        input: {
                            to: {
                                context: otherType.toLowerCase(),
                                id: otherId
                            },
                            message
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        sendMessage: {
                            __typename: 'ComponentConversationMessage',
                            id: this.myConversation.id,
                            updatedAt: new Date(),
                            lastMessage: {
                                _id: 'TEMP_MESSAGE_ID:' + Math.floor(Math.random() * 1000000),
                                message,
                                profileId: this.getMyCurrentProfile().id,
                                createdBy: this.me.id
                            }
                        }
                    },
                    update: this.updateCacheOnSendMessage('sendMessage', {
                        query: MY_CONVERSATION,
                        variables: {
                            id: this.myConversation.id,
                            start: 0,
                            limit: this.limit
                        }
                    })
                })
            }
        },

        async handleSendInitialMessage() {
            const message = this.extractInputText()
            try {
                const { id: otherId, __typename: otherType } = this.$route.params.profile
                const { data } = await this.$apollo.mutate({
                    mutation: SEND_MESSAGE,
                    variables: {
                        input: {
                            to: {
                                context: otherType.toLowerCase(),
                                id: otherId
                            },
                            message
                        }
                    },
                    refetchQueries: [{ query: MY_CONVERSATIONS }]
                })
                console.log('data', data)
                const { id } = data.sendMessage
                this.$router.replace(`/conversation/${id}`)
            } catch (error) {
                console.error('Error when sending initial message:', error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.conversation {
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;

    &__header {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        padding: 3rem 1rem 1rem;
        > :last-child {
            justify-self: end;
        }
    }

    &__input {
        width: 100%;
        padding: 0.5rem 1rem;
        padding-bottom: 2rem;
        display: grid;
        row-gap: 1rem;
        border-top: 1px solid white;
        // height: 100px;
    }
}
</style>
